<template>
  <layout>
    <div class="home">
      <div class="nav_top w">
        <div class="nav_con">
          <div class="azh_logo">
            <img src="@/assets/home/logo.png" alt />
            <span>爱玩就上爱租号</span>
          </div>
          <div class="azh_search">
            <div class="search_lf clearfix">
              <div class="action" @click="gameList(1)">{{ gamename }}</div>
              <div class="action" @click="gameList(2)">{{ gamename1 }}</div>
              <div class="action" @click="gameList(3)">{{ gamename2 }}</div>
              <span></span>
            </div>
            <input
              type="text"
              placeholder="请输入搜索关键词"
              v-model="inputs"
            />
            <button class="ss" @click="inputRef" :disabled="disabled">
              搜索
            </button>
          </div>
          <!-- 级联选择器1 -->
          <div class="inputcard mycode1" ref="mycode1" v-show="isShow">
            <div class="card_letter">
              <ul class="clearfix">
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  @click="chooselist(item, index)"
                  :class="{ aat: action == index }"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="game_list">
              <ul class="clearfix">
                <li
                  v-for="(item, index) in gameLists"
                  :key="index"
                  @click="clickout(item.game_name, item.game_id)"
                >
                  {{ item.game_name }}
                </li>
              </ul>
            </div>
            <div class="unknown">找不到好玩的游戏?</div>
          </div>
          <!-- 2级 -->
          <div class="inputcard card1" v-show="iscard">
            <ul class="clearfix">
              <li
                v-show="gamearea.game_name == null"
                @click="clickout1($event)"
              >
                全区全服
              </li>
              <li
                v-for="(item, index) in gamearea"
                :key="index"
                @click="clickout1($event, item.id)"
              >
                {{ item.game_name }}
              </li>
            </ul>
          </div>
          <!-- 3级 -->
          <div class="inputcard card1" v-show="iscard2">
            <ul>
              <li @click="clickout2($event)">全区全服</li>
              <li
                v-for="(item, index) in gamearea2"
                :key="index"
                @click="clickout2($event)"
              >
                {{ item.game_name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="nav_bom">
          <ul>
            <li class="act a">首页</li>
            <li class="act" @click="onClick('')">爱租号</li>
            <li class="act" @click="$router.push({ path: 'download' })">
              工具下载
            </li>
          </ul>
        </div>
      </div>
      <!-- 头部导航结束 内容部分-->
      <div class="azh_main">
        <!-- 中间部分 -->
        <div class="main_nav w clearfix">
          <div class="main_lf clearfix">
            <div
              class="lf_top"
              v-on:mouseenter="changeActive"
              v-on:mouseleave="removeActive"
            >
              <h4>热门游戏</h4>
              <!-- 侧边导航样式 -->
              <div class="bgs">
                <div
                  class="sild_hot"
                  v-on:mouseenter.stop="changeActive"
                  v-on:mouseleave.stop="removeActive"
                  v-show="ishotShow"
                >
                  <div class="hot_nav">
                    <span>热门租赁</span>
                    <span class="gd" @click="toMore">更多游戏</span>
                  </div>
                  <div class="hot_list">
                    <ul class="clearfix">
                      <li
                        v-for="(item, index) in hotlist"
                        :key="index"
                        @click="onClick(item.game_id)"
                      >
                        <img :src="item.icon" alt />
                        <span>{{ item.game_name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="lf_nav">
              <ul class="clearfix lf_listyx" v-on:mouseleave="removeActivel">
                <li
                  @mouseenter="flag&&changeActivel($event, item.game_id)"
                  @mouseleave="changeActivels"
                  v-for="(item, index) in gameshows"
                  :key="index"
                >
                  {{ item.game_name }}
                </li>
              </ul>
              <!-- 游戏侧边导航样式 -->
              <div
                class="bk"
                v-on:mouseover.stop="changesingle"
                v-on:mouseout.stop="removesingle"
                v-show="isdg"
              >
                <div class="nav_yx">
                  <!-- <div class="yx_banner">
                    <div>热门选择：</div>
                    <span>黑龙瞎</span>
                    <span>黑龙瞎</span>
                    <span>黑龙瞎</span>
                    <span>黑龙瞎</span>
                    <span>黑龙瞎</span>
                  </div> -->
                  <div class="yx_con">
                    <div>{{ gamename3 }}：</div>
                    <span v-for="(item, index) in gamearea" :key="index" @click="clickout3($event,item.id)">{{
                      item.game_name
                    }}</span>
                    <span>全区</span>
                  </div>
                  <ul class="yx_list clearfix">
                    <li v-for="(item, index) in gamearea2" :key="index" @click="clickout4($event)">
                      {{ item.game_name }}
                    </li>
                    <li @click="onClick1()">全服</li>
                  </ul>
                </div>
              </div>
              <div class="lf_bom">
                <div
                  v-for="(item, index) in gametype"
                  :key="index"
                  v-on:mouseenter="changeActives(item.type, item.title)"
                  v-on:mouseleave="removeActives"
                  @click="goTo(index)"
                >
                  <img :src="item.img" alt />
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <!-- 专区游戏导航 -->
              <div
                class="bk"
                v-on:mouseenter.stop="change"
                v-on:mouseleave.stop="remove"
                v-show="isgame"
              >
                <div class="sild_hot syzq">
                  <div class="hot_nav">
                    <span>{{ area }}</span>
                    <span class="gd" @click="toMore">更多游戏</span>
                  </div>
                  <div class="hot_list">
                    <ul class="clearfix">
                      <li
                        v-for="(item, index) in gametypelist"
                        :key="index"
                        @click="onClick(item.game_id)"
                      >
                        <span>{{ item.game_name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main_zj clearfix">
            <swiper v-if="bannerlist.length > 0" :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item, index) in bannerlist" :key="index">
                <div>
                  <img :src="item.cover" alt @click="goJump(item.link_type,item.link_url)"/>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="main_rg">
            <!-- 未登录 -->
            <div class="wdl" v-if="token == null">
              <div class="mazin_photo">
                <img src="@/assets/home/tx.png" alt />
              </div>
              <p>Hi~欢迎来到爱租号</p>
              <div class="login">
                <router-link :to="{ name: 'Login' }" tag="button"
                  >登录</router-link
                >
                <router-link :to="{ name: 'Register' }" tag="button"
                  >注册</router-link
                >
              </div>
            </div>
            <!-- 已登录 -->
            <div class="ydl" v-else>
              <div class="mazin_photo">
                <img :src="Prolist.avatar" alt />
              </div>
              <p class="szys">
                <span>{{ Prolist.nick_name }}</span>
                <i>,欢迎你~</i>
              </p>
              <p class="szys2">
                <span>账号余额:</span>
                <em>{{ Prolist.ext_cash }}</em>
                <span>元</span>
              </p>
            </div>
            <h4>最新播报</h4>
            <div class="bbl">
              <div class="bbbox" v-for="item in tipsMsg" :key="item.id">
                <div class="bb">
                  <span>{{ item.name }}</span>
                  <span>
                    成功租用了{{ item.game_name }}账号
                    <em>{{ item.game_time }}</em
                    >小时
                  </span>
                </div>
              </div>
            </div>
            <div class="shq" @click="$router.push({path:'/download'})">
              <img src="@/assets/home/1.png" alt="">
            </div>
          </div>
        </div>
        <!-- 大家都在玩 -->
        <div class="azh-together w">
          <div class="tgh_top">
            <h4>大家都在玩</h4>
            <div class="gd" @click="toMore">更多游戏</div>
          </div>
          <div class="tgh_con">
            <swiper :options="swiperOption1">
              <swiper-slide v-for="item in hot_game" :key="item.id">
                <div class="card">
                  <img :src="item.hot_icon" alt="" />
                  <div class="bgs">{{ item.game_name }}</div>
                  <div class="dj" @click="onClick(item.game_id)">
                    <img src="@/assets/home/2.png" alt="">
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!-- 电梯导航 -->
    <side-Bar></side-Bar>
    <!-- 红包弹窗 isgit-->
    <div class="hbtc" v-if="isgit">
      <img src="@/assets/home/hbdl.png" alt="" @click="onClick('')" v-if="newIs">
      <img src="@/assets/home/hb.png" alt="" @click="onClick('')" v-else>
      <i class="el-icon-circle-close gban"  @click="outgit"></i>
      <div class="hbnr" v-if="newIs">
        <h4>现金红包</h4>
        <p><i>¥</i>2</p>
      </div>
       <div class="hbnr" v-else>
        <h4>现金红包</h4>
        <p><i>¥</i>5</p>
      </div>
    </div>
    <!-- 底部动画 -->
    <div class="new_hb" v-show="is_hb">
      <img class="hb_zs" src="@/assets/home/homehd.gif" alt="" @click="$router.push({ path: '/member/message' })">
      <img class="gb_xx" @click="hbShow" src="@/assets/home/newreg_close.png" alt="">
    </div>
  </layout>
</template>
<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      newIs:true,
      hb_tips:'',


      ct:null,
      flag: true,//
      is_hb:true,
      isgit:false,
      disabled: false,
      isShow: false, //联级选择
      ishotShow: false, //热门显示隐藏
      isgame: false, //游戏专区显示隐藏
      isdg: false,
      isdj: false,
      iscard: false,
      iscard2: false,
      gameid: "",
      pid: "",
      server: "",
      area1: "",
      gamearea: [], //联级选择二
      gamearea2: [], //
      action: null,
      area: "", //当前游戏专区
      inputs: "",
      gamename: "游戏",
      gamename1: "大区",
      gamename2: "服务器",
      gamename3: "",
      avatar: "",
      game_type: "",
      tipsMsg: [], //消息列表
      hot_game: [],
      bannerlist: [],
      Prolist: [],
      gameLists: [], //游戏列表
      gametypelist: [], //游戏类型数据
      gameshows: [], //左侧单个游戏导航
      hotlist: [], //热门数据
      is_hot: "", //是否热门
      token: null,
      page: 1,
      first_letter: "",
      gametype: [
        {
          title: "手游专区",
          img: require("../../assets/home/syzq1.png"),
          type: 2,
        },
        {
          title: "端游专区",
          img: require("../../assets/home/dyzq1.png"),
          type: 1,
        },
        {
          title: "其他游戏",
          img: require("../../assets/home/qt1.png"),
          type: 3,
        },
        {
          title: "全部游戏",
          img: require("../../assets/home/qb1.png"),
          type: 4,
        },
      ],
      list: [
        "热门",
        "A",
        "B",
        "C",
        "D",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      //轮播设置
      swiperOption: {
        loop: true,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents:true,//修改swiper的父元素时，自动初始化swiper 
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      swiperOption1: {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 25,
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    layout,
    sideBar,
  },
  created() {
    this.getHomeList();
    this.getProfile();
    this.getHotGame();
    this.userId = sessionStorage.getItem("isLogin");
    this.token = sessionStorage.getItem("token");
    //红包显示
   
    if(this.token) {
      if(this.hb_tips == 1){
        this.isgit = true
      }else if(this.hb_tips == 2){
        this.isgit = true
        this.newIs = false
      }
     
      this.is_hb =false
    }else{
       this.isgit =false
    }
  },
  mounted() {
    this.getMyinfo();
    let _this = this;
    document.addEventListener('mouseup',(e) =>{
      let tree = this.$refs.mycode1
      if (tree) {
        if (!tree.contains(e.target)) {
          this.isShow=false
        }
      }
    })
  },
  destroyed() {
     clearTimeout(this.timerID)
  },
  methods: {
    gameList(e) {
      if (e == 1) {
        this.isShow = !this.isShow;
        this.iscard = false;
        this.iscard2 = false;
      } else if (e == 2) {
        if (this.gamearea.length == 0) {
          this.isShow = false;
        } else {
          this.iscard = !this.iscard;
          this.isShow = false;
          this.iscard2 = false;
        }
      } else if (e == 3) {
        if (this.gamearea2.length == 0) {
          this.isShow = false;
        } else {
          this.iscard2 = !this.iscard;
          this.isShow = false;
          this.iscard = false;
        }
      }
    },
    chooselist(item, index) {
      this.action = index;
      if (item == "热门") {
        this.first_letter = "";
        this.game_type = "";
        this.is_hot = "";
      } else {
        this.first_letter = item;
        this.is_hot = "";
        this.game_type = "";
      }
      this.getMyinfo();
    },
    //监听热门移入移除事件
    changeActive() {
      this.ishotShow = true;
      this.is_hot = 1;
      this.game_type = "";
      this.first_letter = "";
      this.getMyinfo();
    },
    removeActive() {
      this.ishotShow = false;
    },
    //专区移入移除
    changeActives(index, title) {
      if (index == 4) {
        this.isgame = false;
      } else {
        this.isgame = true;
      }
      this.game_type = index;
      this.area = title;
      this.is_hot = "";
      this.first_letter = "";
      this.getMyinfo();
    },
    removeActives() {
      this.isgame = false;
    },
    //无奈....
    change() {
      this.isgame = true;
    },
    remove() {
      this.isgame = false;
    },
    //单个游戏移入移除
    changeActivel(e, gameid) {
          this.isdg = true;
          this.gameid = gameid;
          this.gamename3 = e.target.innerHTML;
          this.area1 = this.gamearea[0];
          this.getgameSingle();
      
    },
    changeActivels() {
        var that = this
        that.flag = false
        that.ct = setTimeout(() => {
            that.flag = true
        },17);
    },
    removeActivel() {
      this.isdg = false;
      window.clearTimeout(this.ct)
     
    },
    //无奈...
    changesingle() {
      this.isdg = true;
    },
    removesingle() {
      this.isdg = false;
    },
    //联级搜索1
    clickout(item, id) {
      (this.gamename = item), (this.gameid = id);
      this.isShow = false;
      this.iscard = true;
      this.getFiltrateList();
    },
    //联级搜索2
    clickout1(e, id) {
      this.gamename1 = e.target.innerHTML;
      this.iscard = false;
      this.iscard2 = true;
      if (e.target.innerHTML == "全区全服") {
        this.area1 = "";
      } else {
        this.area1 = e.target.innerText;
        this.pid = id;
        this.getGameArea();
       
      }
    },
    //联级搜索3
    clickout2(e) {
      this.gamename2 = e.target.innerHTML;
      if (e.target.innerHTML == "全区全服") {
        this.server = "";
      } else {
        this.server = e.target.innerText;
      }
      this.iscard2 = false;
    },
    //左侧游戏
    clickout3(e,id){
      this.area1 = e.target.innerText;
      this.pid = id;
      this.getGameArea();
    },
    clickout4(e){
      this.server = e.target.innerText;
        this.$router.push({
          path: "lobby",
          query: {
            game_id: this.gameid,
            server: this.server,
            area: this.area1,
            key_word:''
          }
        })
    },
    //立即试玩
    onClick(index) {
      this.$router.push({
        path:'lobby',
        query:{
          game_id:index,
          area:'',
          server:'',
          key_word:''
        }
      });
    },
    onClick1(){
      this.$router.push({
        path: "lobby",
        query: {
          game_id: this.gameid,
          area:this.area1||'',
          server:'',
          key_word:''
        },
      });
    },
    toMore() {
      this.$router.push({
        path: "lobby",
        query:{
          game_id: '',
          area:'',
          server:'',
          key_word:''
        }
      });
    },
    // 请求轮播图
    getHomeList() {
      let user_id = this.$store.state.user_id;
      this.$get("api/index", { user_id }).then((res) => {
        if (res.code == 0) {
          this.bannerlist = res.ret.banner;
          this.hb_tips = res.ret.hb_tips
        }
      });
    },
	goJump(type,link){
		if(type==2){
			this.$router.push(link)
		}
	},
    //获取个人数据
    getProfile() {
      let user_id = this.$store.state.user_id;
      this.$get("api/getinfo", { user_id }).then((res) => {
        if (res.code === 0) {
          this.Prolist = res.ret;
        }
      });
    },
    //轮播消息列表
    getHotGame(type) {
      let user_id = this.$store.state.user_id;
      this.$get("api/hot_game", { game_type: this.game_type, user_id }).then(
        (res) => {
          if (res.code == 0) {
            this.tipsMsg = res.ret.tips_msg;
            this.hot_game = res.ret.pc_hot;
       
          }
        }
      );
    },
    //游戏筛选列表请求1
    getMyinfo() {
      let user_id = this.$store.state.user_id;
      this.$get("api/game_list", {
        user_id,
        page: this.page,
        game_type: this.game_type,
        first_letter: this.first_letter,
        is_hot: this.is_hot,
      }).then((res) => {
        if(!this.game_type&&!this.is_hot&&!this.first_letter){
          this.gameshows = res.ret.slice(0, 8);
        }
        if (this.game_type) {
          this.gametypelist = res.ret;
        } else if (this.is_hot) {
          this.hotlist = res.ret;
        } else {
          this.gameLists = res.ret;
          
        }
      });
    },
    //联级选择器二级
    getFiltrateList() {
      this.$get("api/game_filtrate_list", {
        game_id: this.gameid,
      }).then((res) => {
        this.gamearea = res.ret.gamearea;
       
      });
    },
    //联级选择器三级
    getGameArea() {
      this.$get("api/game_area", {
        pid: this.pid,
      }).then((res) => {
        this.gamearea2 = res.ret;
      
      });
    },
    //左侧单个游戏展示调用
    getgameSingle() {
      this.$get("api/game_filtrate_list", {
        game_id: this.gameid,
      }).then((res) => {
        if (res.code == 0) {
          this.gamearea = res.ret.gamearea;
          if (this.gamearea.length == 0) {
            return this.gamearea2 = []
          } else {
            this.$get("api/game_area", {
              pid: this.gamearea[0].id,
            }).then((res) => {
                this.gamearea2 = res.ret;
            });
          }
        }
      });
    },
    //监听input内容
    inputRef: function () {
      if (this.inputs) {
        this.$router.push({
          path: "/lobby",
          query: {
          key_word: this.inputs,
          // game_id: this.gameid,
          // server: this.server,
          // area: this.area1,
          },
        });
      } else {
        this.$router.push({
          path: "lobby",
          query: {
            game_id: this.gameid,
            server: this.server,
            area: this.area1,
            key_word:''
          },
        });
      }
    },
    hbShow(){
      this.is_hb =false
    },
    outgit(){
       this.isgit =false
    },


  goTo(index) {
    if(index == 3){
      this.onClick('')
    }
  }
  }
  // watch: {
  //   flag() {
  //      if (!this.flag) {
  //         this.timerID = setTimeout(() =>{
  //         this.flag = true
  //       }, 55);
  //   }
  //    console.log(this.flag,123);
  // }
  // }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .nav_top {
    width: 1200px;
    height: 168px;
    background-color: #fff;
  }
  .nav_con {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
  }
  .inputcard {
    position: absolute;
    right: 0;
    top: 84px;
    width: 690px;
    // height: 440px;
    background-color: #fff;
    z-index: 333;
    border: 1px solid #3c7efe;
    border-radius: 12px;
    padding: 15px 25px;
    box-sizing: border-box;
    li {
      float: left;
    }
    .card_letter {
      ul > li {
        width: 16px;
        height: 16px;
        // opacity: 0.3;
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        margin-left: 5px;
        cursor: pointer;
      }
      li:hover {
        background: #c4d8ff;
        color: #3c7efe;
      }
      .aat {
        color: #fff;
        background: #3c7efe;
      }
      ul li:nth-child(1) {
        margin-right: 40px;
        width: 32px;
        color:#3c7efe;
        background: transparent;
      }
    }
    .game_list {
      ul {
        margin-top: 30px;
        li {
          width: 105px;
          height: 25px;
          margin: 0 20px 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        li:hover {
          color: #3c7efe;
        }
      }
    }
    .unknown {
      width: 100%;
      height: 40px;
      text-align: right;
    }
  }
  //控制显示隐藏
  .block {
    display: block;
  }
  .card1 {
    height: 195px;
    ul li {
      width: 105px;
      margin-bottom: 20px;
    }
    li:hover {
      color: #3c7efe;
    }
  }
  .azh_logo {
    display: flex;
    align-items: center;
    height: 55px;
    width: 350px;
    cursor: pointer;
    img {
      width: 218px;
      height: 55px;
      margin-right: 18px;
    }
    span {
      font-size: 15px;
      line-height: 55px;
    }
  }
  .azh_search {
    width: 690px;
    height: 48px;
    background: #ffffff;
    border: 2px solid #3c7efe;
    border-right: none;
    border-radius: 24px;
    box-sizing: border-box;
    .search_lf {
      position: relative;
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 375px;
      box-sizing: border-box;
      padding-left: 26px;
      // div:nth-child(1) {
      //   // margin-left: 26px;
      // }
      // div:nth-child(3) {
      //   // margin-right: 110px;
      //   // width: 58px;
      // }
      div {
        // width: 45px;
        padding: 0 20px 0 0;
        margin-right: 20px;
        height: 100%;
        line-height: 48px;
        // margin-left: 40px;
      }
      .action {
        position: relative;
        cursor: pointer;
      }
      .action::after {
        position: absolute;
        right: 5px;
        top: 50%;
        content: "";
        display: block;
        width: 6px;
        height: 4px;
        background: url(../../assets/home/xl-ss.png) no-repeat center center;
        background-size: 100% 100%;
      }
      span {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 5px;
        width: 1px;
        height: 22px;
        background: #a1a6b7;
        border-radius: 1px;
      }
    }
    .ss {
      position: relative;
      float: right;
      width: 120px;
      height: 100%;
      background: #3c7efe;
      border-radius: 24px;
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
      padding-left: 20px;
      box-sizing: border-box;
      border-left: 1px solid #a1a6b7;
      cursor: pointer;
    }
    .ss::after {
      position: absolute;
      left: 30px;
      top: 50%;
      margin-top: -10px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(../../assets/home/ss.png) no-repeat center center;
      background-size: 100% 100%;
    }
    input {
      border: none;
      outline-style: none;
      line-height: 44px;
    }
  }
  //头部低部导航
  .nav_bom {
    margin-top: 49px;
    padding-left: 200px;
    width: 100%;
    height: 42px;
    // background-color: pink;
    box-sizing: border-box;
    ul {
      width: 100%;
      height: 100%;
      padding-left: 30px;
      box-sizing: border-box;
      li {
        height: 100%;
        float: left;
        margin-right: 59px;
        line-height: 42px;
        font-size: 14px;
        color: #666;
        font-weight: normal;
        &:nth-child(1){
             font-weight: 700;
             color: #000;
        }
        // &:hover::before {
        //   position: absolute;
        //   bottom: 5px;
        //   left: 50%;
        //   margin-left: -7px;
        //   display: block;
        //   content: "";
        //   width: 14px;
        //   height: 3px;
        //   background-color: #3b7dfd;
        // }
      }
      // li:hover{
      //   font-weight: 700;
      //   color: #000;
      // }
    }
  }
  .act {
    position: relative;
    font-weight: 700;
    color: #000;
    cursor: pointer;
  }
  .a::after {
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -7px;
    display: block;
    content: "";
    width: 14px;
    height: 3px;
    background-color: #3b7dfd;
  }
  //中间导航部分
  .azh_main {
    position: relative;
    background:linear-gradient(#699CFF 20%, #fff );
    // background: url(../../assets/home/lbbj.jpg) no-repeat center center;
    // background-size: 100% 100%;
  }
  .main_nav {
    margin-bottom: 30px;
    .main_lf {
      float: left;
      width: 200px;
      height: 455px;
      background-color: #fff;
      margin-top: -42px;
      margin-right: 10px;
      border-radius: 12px;
      .lf_top {
        position: relative;
        width: 100%;
        height: 42px;
        background: #3c7efe;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        h4 {
          font-size: 16px;
          color: #fff;
          width: 100%;
          height: 100%;
          line-height: 42px;
          text-align: center;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .lf_top::after {
        position: absolute;
        top: 50%;
        left: 24%;
        margin-top: -9px;
        content: "";
        display: block;
        width: 16px;
        height: 18px;
        background: url(../../assets/home/hot.png) no-repeat center center;
      }
      .lf_nav {
        position: relative;
        width: 100%;

        box-sizing: border-box;
        color: #83899d;
        .lf_listyx {
          padding: 13px 16px 0;
          height: 201px;
          li {
            float: left;
            width: 75px;
            height: 30px;
            background: #e1e4eb;
            border-radius: 8px;
            text-align: center;
            line-height: 30px;
            margin-bottom: 17px;
            overflow: hidden;
          }
          li:hover {
            background: #fff;
            border: 1px solid #3c7efe;
            box-sizing: border-box;
            cursor: pointer;
          }
          li:nth-child(odd) {
            margin-right: 16px;
          }
        }
        .lf_bom {
          margin-top: 12px;
          cursor: pointer;
          div {
            padding: 0 16px;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            img {
              margin-right: 16px;
            }
          }
          div:hover {
            background: #3c7efe;
            color: #fff;
          }
        }
        .bk {
          position: absolute;
          left: 200px;
          top: 0;
          width: 730px;
          height: 413px;
          padding: 13px 0 0 10px;
          z-index: 333;
          cursor: pointer;
          .syzq {
            height: 100%;
          }
          .nav_yx {
            padding: 22px;
            height: 413px;
            width: 100%;
            overflow: hidden;
            overflow-y: scroll;
            white-space: nowrap;
            background-color: #fff;
            border: 1px solid #d9dde4;
            border-radius: 12px;
            .yx_banner {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 26px;
              span {
                padding: 0 10px;
                height: 26px;
                color: #3c7efe;
                background-color: #c4d8ff;
                border-radius: 13px;
                text-align: center;
                line-height: 26px;
                margin-right: 16px;
              }
              span:hover {
                color: #fff;
                background: #3c7efe;
              }
            }
            .yx_con {
              display: flex;
              align-items: center;
              margin-top: 35px;
              font-weight: 700;
              height: 25px;
              div {
                font-size: 16px;
                color: #000;
                margin-right: 30px;
              }
              span {
                position: relative;
                font-size: 13px;
                margin-right: 17px;
              }
              span:hover::after {
                position: absolute;
                bottom: -3px;
                left: 50%;
                margin-left: -3px;
                display: block;
                content: "";
                width: 6px;
                height: 3px;
                background: #3c7efe;
                border-radius: 2px;
              }
              span:hover {
                color: #000;
              }
            }
            .yx_list {
              margin-top: 30px;
              li {
                float: left;
                width: 145px;
                height: 25px;
                margin-bottom: 20px;
              }
              li:hover{
                color: #3c7efe;
              }
            }
          }
          .nav_yx::-webkit-scrollbar {
            width: 0;
          }
        }
      }
      //侧边导航样式
      .bgs {
        position: absolute;
        top: 0;
        left: 200px;
        padding-left: 10px;
        z-index: 666;
      }
      //公用样式
      .sild_hot {
        width: 720px;
        height: 330px;
        background: #ffffff;
        border: 1px solid #dadde4;
        border-radius: 12px;
        padding: 35px 20px 0 30px;
        box-sizing: border-box;
        .hot_nav {
          display: flex;
          color: #000;
          justify-content: space-between;
          align-items: center;
          span:nth-child(1) {
            font-size: 16px;
            font-weight: 700;
          }
          .gd {
            position: relative;
            width: 65px;
            height: 24px;
            font-size: 13px;
            line-height: 24px;
          }
          .gd::after {
            position: absolute;
            top: 28%;
            right: 0;
            display: block;
            content: "";
            width: 6px;
            height: 8px;
            background: url(../../assets/home/gd.png) no-repeat center center;
          }
        }
        .hot_list {
          ul {
            margin-top: 30px;
            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 150px;
              float: left;
              margin: 0 17px 25px 0;
              cursor: pointer;
              &:hover{
                color:#3c7efe;
              }
              img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 8px;
              }
              span {
                width: 100px;
                height: 20px;
              }
            }
          }
        }
      }
    }
    .main_zj {
      float: left;
      width: 720px;
      height: 400px;
      background-color: #fff;
      margin-top: 13px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 400px;
      }
    }
    .main_rg {
      float: right;
      width: 260px;
      height: 400px;
      background-color: #fff;
      border-radius: 12px;
      margin-top: 13px;
      padding: 15px 17px 0;
      box-sizing: border-box;
      .mazin_photo {
        position: relative;
        width: 100%;
        height: 80px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 66px;
          height: 66px;
          transform: translate(-50%, -50%);
        }
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #83899d;
        margin-bottom: 10px;
      }
      .szys {
        color: #000;
        font-size: 14px;
        i {
          color: #83899d;
        }
      }
      .szys2 {
        color: #000;
        font-size: 15px;
        em {
          color: #3c7efe;
        }
      }
      .login {
        display: flex;
        justify-content: space-around;
        padding: 0 10px;
        button {
          width: 90px;
          height: 28px;
          border: 1px solid #3b7dfd;
          border-radius: 14px;
          color: #3b7dfd;
          background-color: #fff;
        }
        button:hover {
          background-color: #3c7efe;
          box-shadow:0px 2px 12px #3c7efe;
          color: #fff;
          cursor: pointer;
        }
      }
      h4 {
        margin-top: 30px;
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      .bbl {
        width: 100%;
        height: 75px;
        // display: flex;
        // flex-flow: column;
        // justify-content: space-between;
        overflow: hidden;
        .bbbox {
          -webkit-animation: 20s rowup linear infinite normal;
          animation: 20s rowup linear infinite normal;
          .bb {
            width: 100%;
            height: 25px;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span:nth-child(2) {
              color: #83899d;
              em {
                color: red;
                font-style: normal;
              }
            }
          }
        }
      }
      .shq {
        width: 226px;
        height: 50px;
        margin-top: 30px;
        img{
        cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .azh-together {
    padding-bottom: 90px;
    .tgh_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      h4 {
        font-size: 22px;
        font-family: Microsoft YaHei;
      }
      div {
        width: 110px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #d9dce4;
        border-radius: 19px;
        font-size: 13px;
        text-align: center;
        line-height: 38px;
      }
      .gd {
        position: relative;
        cursor: pointer;
      }
      .gd:hover{
        color: #3C7EFE;
        border: 1px solid #3C7EFE;
      }
      .gd::after {
        position: absolute;
        top: 38%;
        right: 16px;
        display: block;
        content: "";
        width: 6px;
        height: 8px;
        background: url(../../assets/home/gd.png) no-repeat center center;
      }
      .gd:hover::after{
        background: url(../../assets/home/gd2.png) no-repeat center center;
      }
    }
    .tgh_con {
      width: 100%;
      height: 340px;
      // padding: 0 15px;
      box-sizing: border-box;
      .card {
        position: relative;
        width: 280px;
        height: 340px;
        background-color: skyblue;
        border-radius: 12px;
        overflow: hidden;
        .bgs {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 280px;
          height: 62px;
          background: rgba(0, 0, 0, 0.3);
          text-align: center;
          line-height: 62px;
          font-size: 24px;
          color: #fff;
        }
        .dj {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 165px;
          height: 65px;
          z-index: 6;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .card:hover .dj {
        display: block;
      }
      .card:hover .bgs {
        display: none;
      }
    }
  }
  //电梯导航
  .azh-sidebar {
    position: fixed;
    right: 50px;
    top: 400px;
    width: 80px;
    height: 390px;
    background-color: #fff;
    z-index: 99;
    ul > li {
      display: flex;
      flex-flow: column;
      padding-top: 10px;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;
      height: 75px;
      font-size: 12px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
//红包
.hbtc{
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 423px;
  height: 517px;
}
.hbtc img{
  width: 100%;
  height: 100%;
}
.gban{
  position: absolute;
  top: 16px;
  right: 5px;
  font-size: 30px;
  color: #83899D;
}
.hbnr{
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 150px;
  text-align: center;
  h4{
    font-size: 24px;
    color: #FF530C;
  }
  p{
    color: #FF530C;
    font-size: 72px;
    font-weight: 700;
    i{
      font-size: 30px;
      font-weight: 400;
    }
  }
}
//轮播图样式
.card {
  position: relative;
}
.card::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 80px;
  left: 30px;
  background-color: pink;
}
//设置动画
@-webkit-keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -250px, 0);
    transform: translate3d(0, -250px, 0);
  }
}
@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -250px, 0);
    transform: translate3d(0, -250px, 0);
  }
}
//底部动图
.new_hb{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 120px;
   cursor: pointer;
  .hb_zs{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
  }
  .gb_xx{
    position: absolute;
    top: 10px;
    z-index: 102;
    right: 270px;
    width: 33px;
    height: 33px;
  }
}
//步进器修改样式
.swiper-button-prev {
  margin-left: -22px;
  width: 45px;
  height: 45px;
  background: url(../../assets/home/z.png) no-repeat center center;
  background-size: 100% 100%;
}
.swiper-button-next {
  margin-right: -22px;
  width: 45px;
  height: 45px;
  background: url(../../assets/home/y.png) no-repeat center center;
  background-size: 100% 100%;
}
.tgh_con:hover .swiper-button-next {
  background: url(../../assets/home/y1.png) no-repeat center center;
}
.tgh_con:hover .swiper-button-prev {
  background: url(../../assets/home/z1.png) no-repeat center center;
}
</style>